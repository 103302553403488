import React from "react";
import { useInView } from "react-intersection-observer";
import BonusCard from "./BonusCard";

const CasinoDetailsSection = ({
  website,
  referralUrl,
  softwares,
  withdrawalLimit,
  withdrawalMethods,
  depositMethods,
  withdrawalTimes,
  reviews,
  bonuses,
}) => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <section
      className="casino-section casino-details"
      id="casino-details--block_621780a515442"
    >
      <div id="details">
        <h2 className="casino-section__title mb-0">Details</h2>
        <div className="row">
          <div className="col-lg-6 col-md-12 description-column">
            <dl className="casino-details__list">
              <dt className="casino-details__item">Website</dt>
              <dd className="casino-details__definition casino-details__definition--website">
                <a
                  href={referralUrl}
                  target="_blank"
                  rel="noopener sponsored noreferrer"
                >
                  {website}
                </a>
              </dd>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12 description-column">
            <dl className="casino-details__list">
              <dt className="casino-details__item">Software</dt>
              <div className="casino-details__item_list">
                {softwares.length &&
                  softwares.map((software) => {
                    return (
                      <dd
                        key={software}
                        className="casino-details__definition casino-details__definition--software"
                      >
                        {software}{" "}
                      </dd>
                    );
                  })}
              </div>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12 description-column">
            <dl className="casino-details__list">
              <dt className="casino-details__item">Deposit methods</dt>
              <div className="casino-details__item_list">
                {depositMethods.length &&
                  depositMethods.map((method) => {
                    return (
                      <dd
                        key={method}
                        className="casino-details__definition casino-details__definition--withdrawal-method"
                      >
                        {method}
                      </dd>
                    );
                  })}
              </div>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12 description-column">
            <dl className="casino-details__list">
              <dt className="casino-details__item">Withdrawal methods</dt>
              <div className="casino-details__item_list">
                {withdrawalMethods.length &&
                  withdrawalMethods.map((method) => {
                    return (
                      <dd
                        key={method}
                        className="casino-details__definition casino-details__definition--deposit-method"
                      >
                        {method}{" "}
                      </dd>
                    );
                  })}
              </div>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12 description-column descr-no-border">
            <dl className="casino-details__list">
              <dt className="casino-details__item">Payout limits</dt>
              <dd className="casino-details__definition casino-details__definition--withdrawal-limit">
                {withdrawalLimit}
              </dd>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12 description-column descr-no-border">
            <dl className="casino-details__list casino-details__list">
              <dt className="casino-details__item">Payout times</dt>
              <div className="casino-details__item_list">
                {withdrawalTimes?.length &&
                  withdrawalTimes.map((widthTimes) => {
                    return (
                      <li
                        key={widthTimes?.withdrawalMethods}
                        className="casino-details__definition casino-details__list_item casino-details__definition--withdrawal-time "
                      >
                        <p className="bullet"></p>
                        <span>{widthTimes?.withdrawalMethods}:</span>{" "}
                        {widthTimes?.withdrawalTime}
                      </li>
                    );
                  })}
              </div>
            </dl>
          </div>
        </div>
        <section
          className="casino-section casino-reviews"
          id="casino-reviews--block_61c2374c92dfd"
        >
          {reviews && (
            <div id="reviews">
              <h2 className="casino-section__title">Player reviews</h2>
              <div className="casino-reviews__list row">
                {reviews?.length &&
                  reviews.map((review) => {
                    return (
                      <div
                        className="casino-reviews__item col-md-6"
                        key={review?.personName}
                      >
                        <article className="review">
                          <header className="review__header">
                            <div className="review__header">
                              <img
                                src="/images/icons/review-author.svg"
                                alt="Author Avatar"
                                className="review__avatar img-fluid d-block"
                              />
                              <div className="review__person">
                                <h5 className="review__person-name">
                                  {review?.personName}
                                </h5>
                                <span className="review__person-location">
                                  {review?.country}
                                </span>
                              </div>
                            </div>
                            <div className="top-casino-rating casino-hero-rating review-rating">
                              <p>★</p>
                              <span>{review?.rating}/10</span>
                            </div>
                          </header>
                          <div className="review__text entry-content">
                            <p>{review?.description}</p>
                          </div>
                          <footer className="review__footer">
                            <span className="review__date review__person-location">
                              Review submitted {review?.date}
                            </span>
                          </footer>
                        </article>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {bonuses && (
            <section
              className="casino-section casino-bonuses"
              id="casino-bonuses--block_61c2374c92dfe"
              ref={ref}
            >
              <div id="bonuses" className="casino-columns">
                <h2 className="casino-section__title">Newest casino bonuses</h2>
                <div className="casino-bonuses__list casino-columns__list row">
                  {bonuses?.length &&
                    bonuses.map((bonus) => {
                      return (
                        <BonusCard
                          name={bonus?.code}
                          logo={bonus?.bonusLogo}
                          description={bonus?.description}
                          referralUrl={bonus?.referralUrl}
                          bonusBackgroundColor={bonus?.bonusBackgroundColor}
                          key={bonus?.code}
                          partnersTermsAndConditionalLink={
                            bonus?.partnersTermsAndConditionalLink
                          }
                          inView={inView}
                        />
                      );
                    })}
                </div>
              </div>
            </section>
          )}
        </section>
      </div>
    </section>
  );
};

export default CasinoDetailsSection;
