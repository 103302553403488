import React from "react";

const CasinoSideBar = ({ bonuses, reviewsLength }) => {
  return (
    <aside className="casino-sidebar col-xl-2">
      <div className="casino-sidebar__content">
        <h2 className="sr-only">Content navigation</h2>
        <div className="h2 casino-sidebar__title">Navigate</div>
        <ul className="casino-sidebar__navigation casino-navigation color-scheme-blue">
          <li className="casino-navigation__item casino-navigation__item--overview">
            <a href="#overview">Overview</a>
          </li>
          <li className="casino-navigation__item casino-navigation__item--details">
            <a href="#details">Details</a>
          </li>
          <li className="casino-navigation__item casino-navigation__item--reviews">
            <a href="#reviews">
              Player reviews{" "}
              <span className="badge badge-white badge--square">
                {reviewsLength}
              </span>
            </a>
          </li>
          {bonuses?.length && (
            <li className="casino-navigation__item casino-navigation__item--bonuses">
              <a href="#bonuses">
                Casino bonuses{" "}
                <span className="badge badge-white badge--square">
                  {bonuses.length}
                </span>
              </a>
            </li>
          )}
        </ul>
        {bonuses?.length && (
          <div className="card card--casino-welcome-bonus">
            <div className="card-body">
              <p>{bonuses[0]?.description}</p>
              <p>
                <a
                  href={bonuses[0]?.referralUrl}
                  className="btn btn--play-now btn-sm btn-primary btn-block"
                  target="_blank"
                  rel="noopener sponsored noreferrer"
                  data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
                >
                  Get started now!
                </a>
              </p>
            </div>
            {bonuses[0]?.partnersTermsAndConditionalLink && (
              <a
                className="terms-bonus"
                href={bonuses[0]?.partnersTermsAndConditionalLink}
              >
                Terms &amp; conditions
              </a>
            )}
          </div>
        )}
      </div>
    </aside>
  );
};

export default CasinoSideBar;
