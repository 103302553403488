import React, { useState } from "react";
import ContentComponent from "./ContentComponent";

const CasinoBodySection = ({ content, overview }) => {
  const [collapsedBody, setCollapsedBody] = useState(true);
  const negItems = [];
  if (overview) {
    overview.map((overviewItem) => {
      if (overviewItem?.type === "Bad") {
        negItems.push(overviewItem.type);
      }
    });
  }

  return (
    <section className="casino-section casino-content">
      <div id="overview">
        <div className="row row-reviews">
          {overview && (
            <>
              <h2 className="casino-section__title col-md-12">Overview</h2>

              <ul className="casino-cons-pros casino-cons-wrapper color-scheme-blue col-lg-6 col-md-12">
                {overview?.length &&
                  overview.map((overviewItem) => {
                    const type = overviewItem.type.toLowerCase();
                    return (
                      type === "good" && (
                        <li
                          className={`casino-cons-pros__item casino-cons-pros__item--${type}`}
                        >
                          <img src="/images/icons/plus.svg" />
                          {overviewItem.title}
                        </li>
                      )
                    );
                  })}
              </ul>
              {negItems.includes("Bad") && (
                <ul className="casino-cons-pros casino-cons-wrapper color-scheme-blue col-lg-6 col-md-12">
                  {overview?.length &&
                    overview.map((overviewItem) => {
                      const type = overviewItem.type.toLowerCase();
                      return (
                        type === "bad" && (
                          <li
                            className={`casino-cons-pros__item casino-cons-pros__item--${type}`}
                          >
                            <img src="/images/icons/minus.svg" />
                            {overviewItem.title}
                          </li>
                        )
                      );
                    })}
                </ul>
              )}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className={`casino-content__content ${
                collapsedBody === true
                  ? `casino-content__content--collapsed`
                  : ``
              }`}
            >
              <ContentComponent data={content?.childMarkdownRemark?.html} />
            </div>
            <button
              className="btn btn-white casino-content__collapser"
              onClick={() => setCollapsedBody(!collapsedBody)}
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CasinoBodySection;
